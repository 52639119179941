<template>
  <div id="shell"></div>
</template>

<script>
import { Terminal } from "xterm";
import "xterm/css/xterm.css";
import { FitAddon } from "xterm-addon-fit";
export default {
  name: "WebShell",
  data() {
    return {
      shellSocket: null
    };
  },

  computed: {
    vendor() {
      return this.$route.query.vendor;
    },

    region() {
      return this.$route.query.region;
    },

    cluster() {
      return this.$route.query.cluster;
    },

    namespace() {
      return this.$route.query.namespace;
    },

    token() {
      return this.$store.state.app.token;
    },

    userName() {
      return this.$store.state.app.userName;
    },

    userId() {
      return this.$store.state.app.userId;
    },

    organization() {
      return this.$store.state.app.organization;
    }
  },

  methods: {
    shell() {
      let wsProtocol = window.location.protocol === "http:" ? "ws://" : "wss://";
      let pod = this.$route.params.name;
      let container = this.$route.params.container;

      let term = new Terminal({
        cursorStyle: "underline",
        cursorBlink: true
      });

      let fitAddon = new FitAddon();
      term.loadAddon(fitAddon);
      term.open(document.getElementById("shell"));
      fitAddon.fit();

      let params = `X-KubeStar-Token=${this.token}&X-KubeStar-UserID=${this.userId}&X-KubeStar-UserName=${this.userId}&X-KubeStar-Organization=${this.organization}`;

      let url = `${wsProtocol}${window.location.host}/api/v1/k8s.kubestar.io/vendors/${this.vendor}/regions/${this.region}/clusters/${this.cluster}/namespaces/${this.namespace}/pods/${pod}/containers/${container}?${params}`;

      this.shellSocket = new WebSocket(url);

      term.prompt = () => {
        term.write("\r\n ");
      };

      this.shellSocket.onopen = () => {
        term.clear();
        term.prompt();
      };

      this.shellSocket.onmessage = ev => {
        let { Data, Op } = JSON.parse(ev.data);
        if (Op === "stdout") term.write(Data);
      };

      term.onData(e => {
        this.shellSocket.send(JSON.stringify({ Op: "stdin", Data: e }));
      });

      this.shellSocket.onerror = () => {
        term.writeln(`[Connent Error]: Error`);
      };
    }
  },

  mounted() {
    this.shell();
  },

  beforeDestroy() {
    if (this.shellSocket) this.shellSocket.close();
    this.shellSocket = null;
  }
};
</script>

<style lang="scss" scoped>
#shell {
  width: 100%;
  height: 100vh;

  .xterm {
    height: 100%;
  }
}
</style>
